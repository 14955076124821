@font-face {
    font-family: 'Raleway';
    src: url('/src/fonts/Raleway/Raleway-VariableFont_wght.ttf');
}

*{
    font-family:'Raleway';
    font-size: 25px;
    font-weight: 500;
    margin:auto;
    line-height: 200%;
}

img{
    width: 100%;
    height: auto;
}

/*
Main grid of the page
Will contain small information boxes
*/
.grid{
    display: flex;
    flex-direction: column;
    margin-top: 1%;
    width: 70%;

}

.rowEntry{
    display:flex;
    flex-direction: row;
    margin-top: 3%;
    column-gap: 1%;
}

.textBox{
    flex-grow: 1;
    text-align: Left;
    color: #16477E;
}

.textBoxPcOnly{
    flex-grow: 1;
    text-align: Left;
    color: #16477E;
}

.textBoxBlue{
    flex-grow: 1;

    text-align: left;
    height: 12rem;
    width:100%;

    color: white;
    background-color: #16477E;
    padding: 1%;
}

.textBoxBluePcOnly{
    flex-grow: 1;

    text-align: left;
    height: 12rem;
    width:100%;

    color: white;
    background-color: #16477E;
    padding: 1%;
}

.mapBox{
    flex-grow: 3;
    width:170%;
    height:15rem;
    text-align: left;
    color: white;
    background-color: #16477E;
    padding: 1.5%;
}

.footer{
    text-align: center;
    color: white;
    margin-top: 1%;
    width:50rem;
    background-color: #16477E;
    margin-bottom: 1%;
}

.headerText{
    text-align: left;
    font-size: 45px;
    font-weight: 600;
    color: #16477E;
    margin-top: 5%;
}

@media only screen and (max-width: 600px) {

    *{
        font-size: 14px;
        font-weight: 400;
        margin-top: 3%;
    }

    .headerText{
        font-size: 18px;
        font-weight: 600;
        color: #16477E;
        margin-top: 1%;
    }

    .grid{
        flex-wrap: wrap;
        width: 95%;
    }

    .rowEntry{
        flex-wrap: wrap;
    }

    .textBox{
        text-align: center;
        color: #16477E;
    }

    .textBoxBlue{
        flex-grow: 1;
        color: white;
        background-color: #16477E;
        padding: 2%;
        height: fit-content;
        width: 25.455rem;

    }

    .mapBox{
        width:25.455rem;
        height:fit-content;
        align-content: center;
        background-color: #16477E;
        padding: 2%;
    }

    .footer{
        text-align: center;
        color: white;
        margin-top: 1%;
        width: 25.455rem;
        background-color: #16477E;
    }

    .textBoxPcOnly{
        display: none;
    }

    .textBoxBluePcOnly{
        display: none;
    }
}